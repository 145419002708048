<template>
  <LookupEditor lookup-service="stories-service" lookup-type="subcategories" />
</template>

<script>
import LookupEditor from '../components/lookups/LookupEditor';
export default {
  components: {
    LookupEditor,
  },
};
</script>
